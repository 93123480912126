import React from "react";
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "./ui/Dialog";
import { Separator } from "./ui/Separator";
import { Edit2 } from "lucide-react";
import PropTypes from "prop-types";

const EditAccountNameModal = ({
  isOpen,
  onClose,
  onConfirm,
  accountName,
  setAccountName,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex flex-row items-center">
            <Edit2 className="w-6 h-6 mr-2" />
            <span className="text-md">Edit Account Name</span>
          </DialogTitle>
        </DialogHeader>
        <Separator orientation="horizontal" />
        <Input
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
          placeholder="Enter new account name"
        />
        <Separator orientation="horizontal" />
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

EditAccountNameModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  accountName: PropTypes.string.isRequired,
  setAccountName: PropTypes.func.isRequired,
};

export default EditAccountNameModal;
