// app/javascript/components/AutoContributionCalculator.jsx
import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { CALCULATE_AUTO_CONTRIBUTION } from "../mutations/sliceMutations";
import PropTypes from "prop-types";
import { Badge } from "./ui/Badge";
import { Switch } from "./ui/Switch";
import CurrencyInput from "react-currency-input-field";

const AutoContributionCalculator = ({
  targetAmount = 0,
  currentAmount = 0,
  targetDate,
  interval,
  onCalculated,
}) => {
  const [calculateContribution, { loading, data }] = useMutation(
    CALCULATE_AUTO_CONTRIBUTION
  );
  const [amount, setAmount] = useState(null);
  const [useCustomAmount, setUseCustomAmount] = useState(false);
  const [customAmount, setCustomAmount] = useState(0);

  useEffect(() => {
    if (targetAmount && targetDate && interval) {
      calculateContribution({
        variables: {
          targetAmount: targetAmount * 100,
          currentAmount: currentAmount * 100,
          targetDate,
          interval,
        },
      });
    }
  }, [targetAmount, currentAmount, targetDate, interval]);

  useEffect(() => {
    if (data && data.calculateAutoContribution) {
      setAmount(data.calculateAutoContribution.amount);
      if (!useCustomAmount) {
        onCalculated(data.calculateAutoContribution.amount);
      }
    }
  }, [data, useCustomAmount]);

  useEffect(() => {
    if (useCustomAmount) {
      onCalculated(customAmount * 100); // Pass custom amount in cents
    } else if (amount) {
      onCalculated(amount); // Pass calculated amount (already in cents)
    }
  }, [useCustomAmount, customAmount, amount]);

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="flex flex-row gap-2 items-center justify-between w-full">
        <div className="flex flex-row gap-4 w-full">
          <p className="w-2/3 text-sm text-gray-500 my-auto">
            Suggested automatic contribution amount:
          </p>
          {loading ? (
            <p>Calculating...</p>
          ) : amount ? (
            <span className="w-1/3 font-bold text-emerald-600 bg-emerald-50 px-3 py-1 rounded-md border border-emerald-200 flex items-center gap-2 text-sm">
              <svg
                className="w-3 h-3"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              ${(amount / 100).toFixed(2)} {interval}
            </span>
          ) : (
            <p className="text-sm text-gray-500">
              Add a target date and amount to calculate.
            </p>
          )}
        </div>
      </div>

      <div className="flex flex-row items-center justify-between w-full">
        <p className="text-sm text-gray-500 my-auto">
          Use custom amount instead:
        </p>
        <Switch
          checked={useCustomAmount}
          onCheckedChange={(checked) => {
            setUseCustomAmount(checked);
            if (checked) {
              onCalculated(customAmount * 100); // Pass custom amount in cents when switching to custom
            } else if (amount) {
              onCalculated(amount); // Pass calculated amount when switching back
            }
          }}
        />
      </div>

      {useCustomAmount && (
        <div className="w-full">
          <CurrencyInput
            onValueChange={(value) => {
              const numericValue = value ? parseFloat(value) : 0;
              const validAmount = isNaN(numericValue) ? 0 : numericValue;
              setCustomAmount(validAmount);
              onCalculated(validAmount * 100); // Pass custom amount in cents immediately on change
            }}
            placeholder="$0.00"
            decimalsLimit={2}
            allowNegativeValue={false}
            className="flex h-9 w-full rounded-md border border-gray-400 bg-white px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus:ring-pink-vivid-500 disabled:cursor-not-allowed disabled:opacity-50"
            prefix="$"
            value={customAmount}
          />
        </div>
      )}
    </div>
  );
};

AutoContributionCalculator.propTypes = {
  targetAmount: PropTypes.number,
  currentAmount: PropTypes.number,
  targetDate: PropTypes.string,
  interval: PropTypes.string,
  onCalculated: PropTypes.func,
};

export default AutoContributionCalculator;
