import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_SLICES_WITH_UNASSIGNED } from "../queries/sliceQueries";
import CreateSliceModal from "./CreateSliceModal";
import SlicesList from "./SlicesList";
import { Separator } from "./ui/Separator";
import { Input } from "./ui/Input";
import SliceDetails from "./SliceDetails";
import { Spinner } from "./ui/Spinner";
import { GET_CATEGORIES } from "../queries/categoryQueries";
import { GET_ACCOUNTS_FOR_USER } from "../queries/plaidQueries";
import { getCategoryOptions, getSliceOptions } from "../lib/utils";
import { Button } from "./ui/Button";
import { PlusIcon, X } from "lucide-react";
import { SliceFormSheet } from "./SliceFormSheet";

const SlicesHome = () => {
  const [selectedSlice, setSelectedSlice] = useState(null);
  const [categories, setCategories] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSliceFormOpen, setIsSliceFormOpen] = useState(false);
  const [sliceFormMode, setSliceFormMode] = useState("create");

  const { loading: accountsLoading } = useQuery(GET_ACCOUNTS_FOR_USER, {
    onCompleted: (data) => {
      setAccounts(data.accountsForUser);
    },
  });

  const {
    loading: slicesLoading,
    data: slicesData,
    refetch: refetchSlices,
  } = useQuery(GET_SLICES_WITH_UNASSIGNED, {
    fetchPolicy: "network-only",
    variables: {
      includeUnassigned: false,
    },
  });

  const { loading: categoriesLoading } = useQuery(GET_CATEGORIES, {
    onCompleted: (data) => {
      setCategories(data.categories);
    },
  });

  const slices = slicesData ? slicesData.slicesForUser : [];

  const filteredSlices = slices.filter(
    (slice) =>
      slice.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      slice.envelopeType.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (slicesLoading || categoriesLoading || accountsLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex h-screen">
      <div className="w-full lg:w-1/3 lg:max-w-md border-b lg:border-b-0 lg:border-r border-cool-grey-200 flex flex-col h-full">
        <div className="flex-none">
          <div className="flex items-center px-4 py-3 bg-white">
            <h1 className="text-xl font-semibold">Slices</h1>
            <div className="ml-auto">
              <Button
                onClick={() => {
                  setSliceFormMode("create");
                  setIsSliceFormOpen(true);
                }}
              >
                <PlusIcon className="h-4 w-4 mr-2" />
                Add Slice
              </Button>
            </div>
          </div>
          <Separator />
          {slices.length > 0 && (
            <div className="px-4 py-3 bg-white">
              <Input
                placeholder="Search by name or type"
                type="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className="flex-1 overflow-y-auto min-h-0">
          {slices.length > 0 && searchTerm && !filteredSlices.length ? (
            <div className="flex flex-col items-center justify-center h-full p-8 text-center text-gray-500">
              <svg
                className="w-16 h-16 mb-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
              <h3 className="text-lg font-medium mb-2">No matches found</h3>
              <p className="text-sm text-gray-400">
                Try adjusting your search term
              </p>
            </div>
          ) : (
            <SlicesList
              slices={filteredSlices}
              selectedSlice={selectedSlice}
              setSelectedSlice={setSelectedSlice}
            />
          )}
        </div>
      </div>
      <div className="hidden lg:block flex-1 h-full overflow-auto mb-2">
        <SliceDetails
          slice={selectedSlice}
          setSlice={setSelectedSlice}
          sliceOptions={getSliceOptions(slices)}
          categories={categories}
          accounts={accounts}
          categoryOptions={getCategoryOptions(categories)}
          slices={slices}
        />
      </div>
      {selectedSlice && (
        <div className="lg:hidden fixed inset-0 z-50 bg-white overflow-auto">
          <button
            onClick={() => setSelectedSlice(null)}
            className="fixed top-4 right-4 p-2 bg-white rounded-full shadow-lg z-50"
          >
            <X className="h-4 w-4" />
          </button>
          <SliceDetails
            slice={selectedSlice}
            setSlice={setSelectedSlice}
            sliceOptions={getSliceOptions(slices)}
            categories={categories}
            accounts={accounts}
            categoryOptions={getCategoryOptions(categories)}
            slices={slices}
          />
        </div>
      )}
      <SliceFormSheet
        isOpen={isSliceFormOpen}
        onClose={() => {
          setIsSliceFormOpen(false);
          refetchSlices();
        }}
        setSelectedSlice={setSelectedSlice}
        isEditMode={sliceFormMode === "edit"}
        selectedSlice={selectedSlice}
        accounts={accounts}
      />
    </div>
  );
};

export default SlicesHome;
