import React from "react";
import {
  HomeIcon,
  EnvelopeIcon,
  BanknotesIcon,
  ChartBarIcon,
  Cog8ToothIcon,
  ChartPieIcon,
} from "@heroicons/react/24/outline";
import Overview from "../Overview";
import SlicesHome from "../SlicesHome";
import ReportsHome from "../ReportsHome";
import TransactionsHome from "../TransactionsHome";
import SettingsHome from "../SettingsHome";
import {
  CalendarIcon,
  DollarSignIcon,
  ListChecks,
  ListChecksIcon,
} from "lucide-react";
import RulesHome from "../RulesHome";
import UpcomingTransactionsHome from "../UpcomingTransactionsHome";
import SpendingHome from "../SpendingHome";

export interface NavItem {
  label: string;
  icon: React.ElementType;
  id: string;
  component: React.ReactNode;
  href: string;
  current: boolean;
}

export const defaultNavItems: NavItem[] = [
  {
    label: "Overview",
    icon: HomeIcon,
    id: "overview",
    component: <Overview />,
    href: "/",
    current: true,
  },
  {
    label: "Slices",
    id: "slices",
    icon: ChartPieIcon,
    component: <SlicesHome />,
    href: "/slices",
    current: false,
  },
  {
    label: "Upcoming",
    id: "upcoming",
    icon: CalendarIcon,
    component: <UpcomingTransactionsHome />,
    href: "/upcoming",
    current: false,
  },
  {
    label: "Spending",
    id: "spending",
    icon: DollarSignIcon,
    component: <SpendingHome />,
    href: "/spending",
    current: false,
  },
  {
    label: "Transactions",
    id: "transactions",
    icon: BanknotesIcon,
    component: <TransactionsHome setNeedPlaidReconnection={() => {}} />,
    href: "/transactions",
    current: false,
  },
  {
    label: "Rules",
    id: "rules",
    icon: ListChecksIcon,
    component: <RulesHome />,
    href: "/rules",
    current: false,
  },
  {
    label: "Settings",
    id: "settings",
    icon: Cog8ToothIcon,
    component: <SettingsHome />,
    href: "/settings",
    current: false,
  },
];
