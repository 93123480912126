import React, { useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Separator } from "./ui/Separator";
import { Spinner } from "./ui/Spinner";
import { GET_SPENDING_BREAKDOWN } from "../queries/overviewQueries";
import DateRangeSelector from "./ui/DateRangeSelector";
import SpendingBreakdownChart from "./ui/SpendingBreakdownChart";
import { formatCurrency } from "../lib/utils";

const SpendingHome = () => {
  const [selectedPreset, setSelectedPreset] = useState("30");
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
  });

  const {
    data: spendingData,
    loading: spendingLoading,
    error: spendingError,
  } = useQuery(GET_SPENDING_BREAKDOWN, {
    variables: {
      startDate: dateRange?.startDate?.toISOString(),
      endDate: dateRange?.endDate?.toISOString(),
    },
    skip: !dateRange?.startDate || !dateRange?.endDate,
    fetchPolicy: "network-only",
  });

  const handleDateRangeChange = (newDateRange, preset) => {
    setDateRange(newDateRange);
    setSelectedPreset(preset);
  };

  const totalSpending =
    spendingData?.spendingBreakdown?.categoryBreakdown.reduce(
      (sum, item) => sum + Math.abs(item.amount),
      0
    ) || 0;

  const spendingInsights = useMemo(() => {
    if (!spendingData?.spendingBreakdown?.merchantBreakdown) return null;

    const merchants = spendingData.spendingBreakdown.merchantBreakdown;
    const sortedByAmount = [...merchants].sort(
      (a, b) => Math.abs(b.amount) - Math.abs(a.amount)
    );

    return {
      largestPurchase: sortedByAmount[0],
      smallestPurchase: sortedByAmount[sortedByAmount.length - 1],
      averageTransaction:
        merchants.reduce((sum, m) => sum + Math.abs(m.amount), 0) /
        merchants.length,
      totalTransactions: merchants.length,
    };
  }, [spendingData]);

  if (spendingLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  if (spendingError) {
    return <div>Error loading spending data: {spendingError.message}</div>;
  }

  return (
    <div className="flex flex-col h-full w-full overflow-auto">
      <div className="flex py-3">
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-center px-4 pb-4">
            <div className="flex flex-row items-center justify-between w-full">
              <h2 className="text-xl font-semibold">Spending Analysis</h2>
              <DateRangeSelector
                initialDateRange={dateRange}
                onDateRangeChange={handleDateRangeChange}
                selectedPreset={selectedPreset}
                setSelectedPreset={setSelectedPreset}
              />
            </div>
          </div>
          <Separator />
          <div className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-2 md:px-4">
              <Card className="col-span-1 md:col-span-2">
                <CardHeader className="space-y-2">
                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <CardTitle>Category Breakdown</CardTitle>
                    <div className="text-sm text-gray-500 mt-1 sm:mt-0">
                      Total: {formatCurrency(totalSpending)}
                    </div>
                  </div>
                </CardHeader>
                <CardContent className="h-[300px] md:h-[400px]">
                  {spendingData?.spendingBreakdown?.categoryBreakdown ? (
                    <SpendingBreakdownChart
                      data={spendingData.spendingBreakdown.categoryBreakdown}
                    />
                  ) : (
                    <div className="flex items-center justify-center h-full text-gray-500">
                      <div className="flex flex-col items-center">
                        <p className="text-lg font-medium">No data available</p>
                        <p className="text-sm">
                          Try selecting a different date range
                        </p>
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>

              <Card className="col-span-1">
                <CardHeader>
                  <CardTitle>Spending Insights</CardTitle>
                </CardHeader>
                <CardContent className="p-4 sm:p-6">
                  <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 sm:gap-6">
                    {spendingInsights ? (
                      <>
                        <div>
                          <h3 className="text-sm font-medium text-gray-500">
                            Largest Purchase
                          </h3>
                          <p className="mt-1 text-2xl font-semibold">
                            {formatCurrency(
                              spendingInsights.largestPurchase.amount
                            )}
                          </p>
                          <p className="text-sm text-gray-500">
                            {spendingInsights.largestPurchase.merchantName ||
                              "Unknown Merchant"}
                          </p>
                        </div>
                        <div>
                          <h3 className="text-sm font-medium text-gray-500">
                            Average Transaction
                          </h3>
                          <p className="mt-1 text-2xl font-semibold">
                            {formatCurrency(
                              spendingInsights.averageTransaction
                            )}
                          </p>
                        </div>
                        <div>
                          <h3 className="text-sm font-medium text-gray-500">
                            Transaction Count
                          </h3>
                          <p className="mt-1 text-2xl font-semibold">
                            {spendingInsights.totalTransactions}
                          </p>
                        </div>
                      </>
                    ) : (
                      <div className="text-center text-gray-500">
                        No data available
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>

              <Card className="col-span-1 md:col-span-2 lg:col-span-3">
                <CardHeader>
                  <CardTitle>Top Merchants</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {[
                      ...(spendingData?.spendingBreakdown?.merchantBreakdown ||
                        []),
                    ]
                      .sort((a, b) => Math.abs(b.amount) - Math.abs(a.amount))
                      .slice(0, 6)
                      .map((merchant) => (
                        <div
                          key={merchant.merchantName || "unknown"}
                          className="flex justify-between items-center p-3 sm:p-4 bg-gray-50 rounded-lg"
                        >
                          <span className="font-medium text-sm sm:text-base truncate mr-2">
                            {merchant.merchantName || "Unknown Merchant"}
                          </span>
                          <span className="text-gray-600 font-semibold whitespace-nowrap">
                            {formatCurrency(merchant.amount)}
                          </span>
                        </div>
                      ))}
                  </div>
                </CardContent>
              </Card>

              <Card className="col-span-1 md:col-span-2 lg:col-span-3">
                <CardHeader>
                  <CardTitle>Category Details</CardTitle>
                </CardHeader>
                <CardContent className="p-0 sm:p-6">
                  <div className="overflow-x-auto">
                    <table className="w-full border-collapse">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="border p-2 sm:p-3 text-left text-sm font-medium text-gray-700">
                            Category
                          </th>
                          <th className="border p-2 sm:p-3 text-right text-sm font-medium text-gray-700">
                            Amount
                          </th>
                          <th className="border p-2 sm:p-3 text-right text-sm font-medium text-gray-700">
                            % of Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {[
                          ...(spendingData?.spendingBreakdown
                            ?.categoryBreakdown || []),
                        ]
                          .sort(
                            (a, b) => Math.abs(b.amount) - Math.abs(a.amount)
                          )
                          .map((category) => (
                            <tr key={category.category.id}>
                              <td className="border p-2 sm:p-3">
                                {category.category.name}
                              </td>
                              <td className="border p-2 sm:p-3 text-right">
                                {formatCurrency(category.amount)}
                              </td>
                              <td className="border p-2 sm:p-3 text-right">
                                {(
                                  (Math.abs(category.amount) / totalSpending) *
                                  100
                                ).toFixed(1)}
                                %
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpendingHome;
