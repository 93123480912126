import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_TRANSACTION } from "../mutations/transactionMutations";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/Form";
import { Input } from "./ui/Input";
import { Button } from "./ui/Button";
import CurrencyInput from "react-currency-input-field";
import toast from "react-hot-toast";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "./ui/Select";
import { Textarea } from "./ui/TextArea";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetFooter,
} from "./ui/Sheet";
import { RadioGroup, RadioGroupItem } from "./ui/RadioGroup";
import { CREATE_CATEGORY } from "../mutations/categoryMutation";
import { GET_CATEGORIES } from "../queries/categoryQueries";
import { CategoryModal } from "./CategoryModal";

const formSchema = z.object({
  transactionName: z.string().min(1, { message: "Description is required" }),
  amount: z.number().min(0.01, { message: "Please enter a valid amount" }),
  merchantName: z.string().optional(),
  categoryId: z.string().optional(),
  envelopeId: z.string().optional(),
  notes: z.string().optional(),
  isInflow: z.boolean().optional(),
});

interface TransactionFormProps {
  transaction: {
    id: string;
    amount: number;
    merchantName: string;
    category: {
      id: string;
      name: string;
      icon: string;
    };
    transactionName: string;
    envelopeId: string;
    notes?: string;
    isInflow: boolean;
  };
  categories: { id: string; name: string; icon: string }[];
  slices: { id: string; name: string }[];
  onClose: () => void;
  isOpen: boolean;
}

export const TransactionForm: React.FC<TransactionFormProps> = ({
  transaction,
  onClose,
  slices,
  isOpen,
}) => {
  const { data: categoriesData, refetch: refetchCategories } =
    useQuery(GET_CATEGORIES);
  const [categories, setCategories] = useState([]);
  const [updateTransaction] = useMutation(UPDATE_TRANSACTION);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      amount: transaction ? transaction.amount / 100 : 0,
      isInflow: transaction ? transaction.isInflow : false,
      merchantName: transaction?.merchantName || "",
      categoryId: transaction?.category?.id || "",
      transactionName: transaction?.transactionName || "",
      envelopeId: transaction?.envelopeId || "",
      notes: transaction?.notes || "",
    },
  });

  useEffect(() => {
    if (categoriesData) {
      setCategories(categoriesData.categories);

      if (selectedCategory) {
        const category = categoriesData.categories.find(
          (c) => c.id === selectedCategory.id
        );
        if (category) {
          setSelectedCategory(category);
          form.setValue("categoryId", category.id, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }
      }
    }
  }, [categoriesData]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      await updateTransaction({
        variables: {
          id: transaction.id,
          amount: Math.round(values.amount * 100),
          merchantName: values.merchantName,
          categoryId: selectedCategory?.id || values.categoryId || null,
          transactionName: values.transactionName,
          envelopeId:
            values.envelopeId === "unassigned" ? null : values.envelopeId,
          notes: values.notes,
          isInflow: values.isInflow,
        },
      });
      toast.success("Transaction updated successfully");
      onClose();
    } catch (error) {
      toast.error("Failed to update transaction");
    }
  };

  const [createCategory] = useMutation(CREATE_CATEGORY);

  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const handleCategoryChange = async (value: string) => {
    if (value === "create-new") {
      setShowCategoryModal(true);
    } else {
      form.setValue("categoryId", value);
    }
  };

  const handleCreateCategory = async (name: string) => {
    try {
      const { data } = await createCategory({
        variables: { name },
      });

      if (data.createCategory.errors.length) {
        toast.error(data.createCategory.errors[0]);
        form.setValue("categoryId", "");
        return;
      }

      const newCategory = data.createCategory.category;
      const updatedCategories = [...categories, newCategory];
      setCategories(updatedCategories);

      toast.success("Category created successfully");
      form.setValue("categoryId", newCategory.id, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });

      setSelectedCategory(newCategory);
      setShowCategoryModal(false);
    } catch (error) {
      toast.error("Failed to create category");
      form.setValue("categoryId", "");
    }
  };

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent className="w-full sm:max-w-lg overflow-y-auto">
        <SheetHeader className="space-y-4">
          <SheetTitle>Edit Transaction</SheetTitle>
        </SheetHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-6 py-6"
          >
            <FormField
              control={form.control}
              name="transactionName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm font-medium">
                    Description
                  </FormLabel>
                  <FormControl>
                    <Input {...field} className="h-10" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <FormField
                control={form.control}
                name="amount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">
                      Amount
                    </FormLabel>
                    <FormControl>
                      <CurrencyInput
                        id="amount"
                        name="amount"
                        defaultValue={field.value}
                        decimalsLimit={2}
                        onValueChange={(value) =>
                          field.onChange(parseFloat(value || "0"))
                        }
                        prefix="$"
                        className="h-10 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="isInflow"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">Type</FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={(value) =>
                          field.onChange(value === "inflow")
                        }
                        defaultValue={field.value ? "inflow" : "outflow"}
                        className="flex gap-4"
                      >
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem value="inflow" id="inflow" />
                          <label htmlFor="inflow" className="text-sm">
                            Income
                          </label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem value="outflow" id="outflow" />
                          <label htmlFor="outflow" className="text-sm">
                            Expense
                          </label>
                        </div>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <FormField
                control={form.control}
                name="merchantName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">
                      Merchant
                    </FormLabel>
                    <FormControl>
                      <Input {...field} className="h-10" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="categoryId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">
                      Category
                    </FormLabel>
                    <Select
                      onValueChange={handleCategoryChange}
                      value={selectedCategory?.id || field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="h-10">
                          <SelectValue placeholder="Select a category" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="create-new">
                          + Create New Category
                        </SelectItem>
                        {categories.map((category) => (
                          <SelectItem key={category.id} value={category.id}>
                            {category.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="envelopeId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm font-medium">
                    Assigned Slice
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={
                      field.value === "" ? "unassigned" : field.value
                    }
                  >
                    <FormControl>
                      <SelectTrigger className="h-10">
                        <SelectValue placeholder="Select a slice" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="unassigned">Unassigned</SelectItem>
                      {slices.map((slice) => (
                        <SelectItem key={slice.id} value={slice.id}>
                          {slice.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm font-medium">Notes</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      className="min-h-[100px] resize-none"
                      placeholder="Add notes about this transaction"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <SheetFooter className="flex-row gap-2 sm:justify-end pt-4">
              <Button
                type="button"
                variant="outline"
                onClick={onClose}
                className="flex-1 sm:flex-none"
              >
                Cancel
              </Button>
              <Button type="submit" className="flex-1 sm:flex-none">
                Update Transaction
              </Button>
            </SheetFooter>
          </form>
        </Form>
        <CategoryModal
          isOpen={showCategoryModal}
          onClose={() => setShowCategoryModal(false)}
          onCreateCategory={handleCreateCategory}
        />
      </SheetContent>
    </Sheet>
  );
};
