import React from "react";
import PropTypes from "prop-types";
import { AlertTriangle } from "lucide-react";
import { Separator } from "./ui/Separator";
import {
  AlertDialog,
  AlertDialogActionDestructive,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/AlertDialog";

const RemoveAccountModal = ({
  isOpen,
  onClose,
  onConfirm,
  name,
  type,
  isRemoving,
}) => {
  const itemType = type === "institution" ? "institution" : "account";
  const capitalizedType = itemType.charAt(0).toUpperCase() + itemType.slice(1);

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="bg-white sm:max-w-[425px]">
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center gap-2">
            <AlertTriangle color="red" className="w-6 h-6 mr-2" />
            Remove {capitalizedType}
          </AlertDialogTitle>
          <Separator orientation="horizontal" className="w-full" />
          <AlertDialogDescription className="flex flex-col gap-2">
            <span className="text-black">
              Are you sure you want to remove the {itemType}{" "}
              <strong>&ldquo;{name}&ldquo;</strong>?
            </span>
            <span className="text-red-500">This action cannot be undone.</span>
            {type === "institution" && (
              <span className="text-black font-semibold">
                Note: Removing an institution will remove all associated
                accounts and transactions.
              </span>
            )}
            {type === "account" && (
              <span className="text-black font-semibold">
                Note: Removing an account will remove all associated
                transactions.
              </span>
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <Separator orientation="horizontal" className="w-full" />
        <AlertDialogFooter className="flex justify-between w-full">
          <AlertDialogCancel onClick={onClose} disabled={isRemoving}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogActionDestructive
            onClick={onConfirm}
            disabled={isRemoving}
          >
            {isRemoving ? "Removing..." : "Remove"}
          </AlertDialogActionDestructive>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

RemoveAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["account", "institution"]).isRequired,
  isRemoving: PropTypes.bool.isRequired,
};

export default RemoveAccountModal;
