import React, { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigate,
  createRoutesFromChildren,
  matchRoutes,
  useNavigationType,
} from "react-router-dom";
import App from "../components/App";
import SlicesHome from "../components/SlicesHome";
import ErrorPage from "../components/layout/ErrorPage";
import SettingsHome from "../components/SettingsHome";
import TransactionsHome from "../components/TransactionsHome";
import UserSignIn from "../components/UserSignIn";
import UserSignUp from "../components/UserSignUp";
import Layout from "../components/layout/Layout";
import { ApolloProvider } from "@apollo/client";
import { client } from "../graphqlProvider";
import RulesHome from "../components/RulesHome";
import { UserProvider, useUser } from "../lib/UserContext";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import UpcomingTransactionsHome from "../components/UpcomingTransactionsHome";
import SpendingHome from "../components/SpendingHome";

const container = document.getElementById("app");
const root = createRoot(container);

Sentry.init({
  dsn: "https://2d064dc077b015dda60813270356fb05@o4507790456389632.ingest.us.sentry.io/4507790462156800",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const ProtectedRoute = ({ children }) => {
  const { isSessionValid, validateSession } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isSessionValid) {
      validateSession({
        onCompleted: (data) => {
          if (!data.validateSession.valid) {
            navigate("/login", { state: { from: location } });
          }
        },
        onError: () => {
          navigate("/login", { state: { from: location } });
        },
      });
    }
  }, [location.pathname, isSessionValid, validateSession, navigate, location]);

  return isSessionValid ? children : null;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "slices/",
        element: <SlicesHome />,
      },
      {
        path: "transactions/",
        element: <TransactionsHome />,
      },
      {
        path: "upcoming/",
        element: <UpcomingTransactionsHome />,
      },
      {
        path: "spending/",
        element: <SpendingHome />,
      },
      {
        path: "rules/",
        element: <RulesHome />,
      },
      {
        path: "settings/",
        element: <SettingsHome />,
      },
    ],
  },
  {
    path: "/login",
    element: <UserSignIn />,
  },
  {
    path: "/register",
    element: <UserSignUp />,
  },
]);

document.addEventListener("DOMContentLoaded", () => {
  const handleSessionInvalid = () => {
    console.log("session invalid");
    router.navigate("/login");
  };
  window.addEventListener("session-invalid", handleSessionInvalid);
  root.render(
    <StrictMode>
      <ApolloProvider client={client}>
        <UserProvider>
          <RouterProvider router={router}>
            <App />
          </RouterProvider>
        </UserProvider>
      </ApolloProvider>
    </StrictMode>
  );
  return () => {
    window.removeEventListener("session-invalid", handleSessionInvalid);
  };
});
