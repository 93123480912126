import { gql } from "@apollo/client";

const EXCHANGE_PUBLIC_TOKEN = gql`
  mutation exchangePublicToken($publicToken: String!) {
    exchangePublicToken(input: { publicToken: $publicToken }) {
      success
      errors
    }
  }
`;

const CREATE_PLAID_LINK_TOKEN = gql`
  mutation createPlaidLinkToken($plaidItemId: ID) {
    createPlaidLinkToken(input: { plaidItemId: $plaidItemId }) {
      linkToken
      errors
    }
  }
`;

const SYNC_PLAID_ACCOUNTS = gql`
  mutation syncPlaidAccounts {
    syncPlaidAccounts(input: {}) {
      accounts {
        id
        plaidAccountId
        name
        officialName
        subtype
        accountType
        availableBalance
        currentBalance
        isoCurrencyCode
        mask
        plaidItem {
          id
          institutionId
        }
      }
      errors
    }
  }
`;

const SYNC_PLAID_TRANSACTIONS = gql`
  mutation syncPlaidTransactions {
    syncPlaidTransactions(input: {}) {
      jobId
      errors
    }
  }
`;

const TRIGGER_PLAID_SYNC = gql`
  mutation TriggerPlaidSync($force: Boolean!) {
    triggerPlaidSync(input: { force: $force }) {
      jobId
      error
    }
  }
`;

const REMOVE_PLAID_ACCOUNT = gql`
  mutation RemovePlaidAccount($accountId: ID, $plaidItemId: ID) {
    removePlaidAccount(
      input: { accountId: $accountId, plaidItemId: $plaidItemId }
    ) {
      success
      errors
    }
  }
`;

const UPDATE_ACCOUNT_NAME = gql`
  mutation UpdateAccountName($accountId: ID!, $name: String!) {
    updateAccountName(input: { accountId: $accountId, name: $name }) {
      account {
        id
        name
      }
      errors
    }
  }
`;

export {
  EXCHANGE_PUBLIC_TOKEN,
  CREATE_PLAID_LINK_TOKEN,
  SYNC_PLAID_ACCOUNTS,
  SYNC_PLAID_TRANSACTIONS,
  TRIGGER_PLAID_SYNC,
  REMOVE_PLAID_ACCOUNT,
  UPDATE_ACCOUNT_NAME,
};
