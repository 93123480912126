import { gql } from "@apollo/client";

const GET_SLICES_FOR_USER = gql`
  query getSlicesForUser {
    slicesForUser {
      name
      currentAmount
      id
      targetDate
      targetAmount
      createdAt
      updatedAt
      envelopeType
      spending
      notes
      color
      status
      isRecurring
      continueAfterGoal
      resetFrequency
      contributionStartDate
      transactionCount
      rules {
        id
        name
        ruleCategory
        active
        ruleConditions {
          id
          field
          operator
          value
        }
        ruleActions {
          id
          actionType
          parameters
        }
        ruleSchedule {
          id
          interval
          nextRun
        }
      }
      autoContribution {
        id
        envelopeId
        sourceAccountId
        amount
        interval
        nextContributionDate
      }
    }
  }
`;

const GET_SLICES_WITH_UNASSIGNED = gql`
  query GetSlicesForUser(
    $includeUnassigned: Boolean!
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $accountIds: [String!]
    $sliceIds: [String!]
    $categoryIds: [String!]
    $searchTerm: String
  ) {
    slicesForUser(
      includeUnassigned: $includeUnassigned
      startDate: $startDate
      endDate: $endDate
      accountIds: $accountIds
      sliceIds: $sliceIds
      categoryIds: $categoryIds
      searchTerm: $searchTerm
    ) {
      id
      name
      transactionCount
      currentAmount
      targetAmount
      targetDate
      envelopeType
      spending
      notes
      color
      status
      isRecurring
      continueAfterGoal
      resetFrequency
      contributionStartDate
      autoContributeAmount
      autoContributeInterval
      autoContribution {
        id
        envelopeId
        sourceAccountId
        amount
        interval
        nextContributionDate
      }
    }
  }
`;

const GET_AUTO_CONTRIBUTION = gql`
  query getAutoContribution($envelopeId: ID!) {
    autoContribution(envelopeId: $envelopeId) {
      id
      envelopeId
      sourceAccountId
      amount
      nextContributionDate
      interval
    }
  }
`;

export {
  GET_SLICES_FOR_USER,
  GET_AUTO_CONTRIBUTION,
  GET_SLICES_WITH_UNASSIGNED,
};
