import React from "react";
import { Transaction } from "../lib/transactionSchema";
import { formatCurrency, formatDate } from "../lib/utils";
import { Badge } from "./ui/Badge";
import { Separator } from "./ui/Separator";
import * as LucideIcons from "lucide-react";
import { Button } from "./ui/Button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetFooter,
} from "./ui/Sheet";

interface TransactionDetailsViewProps {
  transaction: Transaction;
  onClose: () => void;
  onEdit: () => void;
  isOpen: boolean;
}

const TransactionDetailsView: React.FC<TransactionDetailsViewProps> = ({
  transaction,
  onClose,
  onEdit,
  isOpen,
}) => {
  const IconComponent =
    transaction.category?.icon &&
    LucideIcons[transaction.category.icon as keyof typeof LucideIcons]
      ? LucideIcons[transaction.category.icon as keyof typeof LucideIcons]
      : LucideIcons.HelpCircle;

  const detailRows = [
    { label: "Date", value: formatDate(transaction.transactionDate) },
    {
      label: "Status",
      value:
        transaction.status === "pending" ? (
          <span className="italic text-yellow-600">Pending</span>
        ) : (
          <span className="text-green-600">Posted</span>
        ),
    },
    { label: "Amount", value: formatCurrency(transaction.amount) },
    { label: "Category", value: transaction.category?.name || "Uncategorized" },
    { label: "Account", value: transaction.accountName },
    {
      label: "Merchant",
      value: (
        <div className="flex items-center gap-2">
          {transaction.merchantLogoUrl && (
            <img
              src={transaction.merchantLogoUrl}
              alt="Merchant Logo"
              className="h-5 w-auto"
            />
          )}
          <span>{transaction.merchantName || "N/A"}</span>
        </div>
      ),
    },
    {
      label: "Assigned Slice",
      value: transaction.assignedSliceName ? (
        <Badge variant={transaction.assignedSliceType as any}>
          {transaction.assignedSliceName}
        </Badge>
      ) : (
        <Badge variant="outline">Unassigned</Badge>
      ),
    },
    {
      label: "Notes",
      value: transaction.notes || "",
    },
  ];

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent className="w-full sm:max-w-lg">
        <SheetHeader className="space-y-4">
          <SheetTitle>Transaction Details</SheetTitle>
        </SheetHeader>

        <div className="mt-6 flex flex-col space-y-6">
          <div className="rounded-lg bg-gray-50 p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <IconComponent className="h-8 w-8 text-gray-600" />
                <h2 className="text-lg font-semibold text-gray-900">
                  {transaction.transactionName}
                </h2>
              </div>
              <span className="text-lg font-semibold text-gray-900">
                {formatCurrency(transaction.amount)}
              </span>
            </div>
          </div>

          <div className="space-y-4">
            {detailRows.map((row, index) => (
              <div key={index}>
                <div className="flex items-center justify-between py-2">
                  <span className="text-sm font-medium text-gray-500">
                    {row.label}
                  </span>
                  <span className="text-sm text-gray-900">{row.value}</span>
                </div>
                {index < detailRows.length - 1 && (
                  <Separator className="my-1" />
                )}
              </div>
            ))}
          </div>
        </div>

        <SheetFooter className="mt-6 flex-row gap-2 sm:justify-end">
          <Button
            onClick={onClose}
            variant="outline"
            className="flex-1 sm:flex-none"
          >
            Close
          </Button>
          <Button
            onClick={onEdit}
            variant="default"
            className="flex-1 sm:flex-none"
          >
            Edit
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default TransactionDetailsView;
