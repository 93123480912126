import { gql } from "@apollo/client";

const GET_UPCOMING_TRANSACTIONS = gql`
  query GetUpcomingTransactions($daysAhead: Int) {
    upcomingTransactions(daysAhead: $daysAhead) {
      id
      description
      merchantName
      frequency
      averageAmount
      nextOccurrence
      isActive
      category {
        name
        icon
      }
    }
  }
`;

export { GET_UPCOMING_TRANSACTIONS };
