import React from "react";
import { useQuery } from "@apollo/client";
import { format } from "date-fns";
import { GET_UPCOMING_TRANSACTIONS } from "../queries/recurringQueries";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Separator } from "./ui/Separator";
import { Spinner } from "./ui/Spinner";

const UpcomingTransactionsHome = () => {
  const { data, loading, error } = useQuery(GET_UPCOMING_TRANSACTIONS, {
    variables: { daysAhead: 30 },
  });

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>Error loading recurring transactions: {error.message}</div>;
  }

  return (
    <div className="flex flex-col h-full w-full overflow-auto">
      <div className="flex py-3">
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-center px-4 pb-4">
            <div className="flex flex-row items-center justify-between w-full">
              <h2 className="text-xl font-semibold">Upcoming Transactions</h2>
            </div>
          </div>
          <Separator />
          <div className="p-4">
            <Card>
              <CardHeader>
                <CardTitle>Next 30 Days</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4">
                  {data?.upcomingTransactions.map((transaction) => (
                    <div
                      key={transaction.id}
                      className="bg-white rounded-lg shadow p-4 flex justify-between items-center"
                    >
                      <div className="flex items-center gap-4">
                        <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                          <i className={transaction.category.icon} />
                        </div>
                        <div>
                          <h3 className="font-semibold">
                            {transaction.description}
                          </h3>
                          <p className="text-sm text-gray-600">
                            {transaction.category.name}
                          </p>
                        </div>
                      </div>
                      <div className="text-right">
                        <p className="font-semibold">
                          ${(transaction.averageAmount / 100).toFixed(2)}
                        </p>
                        <p className="text-sm text-gray-600">
                          Next:{" "}
                          {format(
                            new Date(transaction.nextOccurrence),
                            "MMM d, yyyy"
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingTransactionsHome;
